import React,{ useState, useRef } from 'react';
import './styles.css';
import { useSpring, animated } from 'react-spring';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeImg from '../../images/youtubeImg.png';
import Arrow from '../../images/arrow.svg';
import RightLazyShow from '../rightLazyShow';

const OAR = () => {
  
    return(
      <>
        <div className="OARMain" id="oar">
          <text className="whyStakeWithmeHeaderText">ORGANIZATION FOR AUTISM RESEARCH (OAR)</text>
          <div className="row">
            <div className="col-lg-6 col1">
              <iframe src="https://www.youtube.com/embed/72x6dTOij4k" loading="lazy" frameBorder="0" allowFullScreen=""  className="youtubeImg"></iframe>
              <div className="ORARow">
                <div className="ORABlueSubHeaderDiv">
                {/* <RightLazyShow> */}
                  <text className="ORABlueHeader" onClick={()=> window.open('https://hi.switchy.io/aboutOAR', '_blank')}>GET TO KNOW THEM</text>
                  <a href="https://hi.switchy.io/aboutOAR" target="_blank" rel="noreferrer"><img src={Arrow} alt="location" className="arrowImg"/></a>
                  {/* </RightLazyShow> */}
                </div>
                <div className="ORABlueSubHeaderDiv">
                {/* <RightLazyShow> */}
                  <text className="ORABlueHeader" onClick={()=> window.open('https://hi.switchy.io/donateToOAR', '_blank')}>DONATE DIRECTLY</text>
                  <a href="https://hi.switchy.io/donateToOAR" target="_blank" rel="noreferrer"><img src={Arrow} alt="location" className="arrowImg" id="arrowimg2"/></a>
                {/* </RightLazyShow> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 gettoknowCol">
              <Card>
                <text className="whyMeMainText">
                  {/* <RightLazyShow> */}
                    I wanted to benefit an autism-related charity because it is a hidden disease that is commonly misunderstood. I chose OAR because:
                  {/* </RightLazyShow> */}
                </text>
                <div className="ORARow OARRow2">
                  <div className="numberDiv">
                    <text className="whyMeMainText">
                      {/* <RightLazyShow> */}
                        1
                      {/* </RightLazyShow> */}
                    </text>
                  </div>
                  <div className="textDiv">
                    <text className="whyMeMainText">
                      {/* <RightLazyShow> */}
                        Among other things, they focus on helping those with autism find employment. I believe having a job and purpose is a major key to life fulfillment
                      {/* </RightLazyShow> */}
                    </text>
                  </div>
                </div>
                <div className="ORARow OARRow2">
                  <div className="numberDiv">
                    <text className="whyMeMainText">
                      {/* <RightLazyShow> */}
                        2
                      {/* </RightLazyShow> */}
                    </text>
                  </div>
                  <div className="textDiv">
                    <text className="whyMeMainText">
                      {/* <RightLazyShow> */}
                        They are a high-efficiency charity so I can be confident dollars donated are actually going towards the cause.
                      {/* </RightLazyShow> */}
                    </text>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className="separatingLine"></div>
      </>
    )

    function Card({ children }) {
      // We add this ref to card element and use in onMouseMove event ...
      // ... to get element's offset and dimensions.
      const ref = useRef();
    
      // Keep track of whether card is hovered so we can increment ...
      // ... zIndex to ensure it shows up above other cards when animation causes overlap.
      const [isHovered, setHovered] = useState(false);
    
      const [animatedProps, setAnimatedProps] = useSpring(() => {
        return {
          // Array containing [rotateX, rotateY, and scale] values.
          // We store under a single key (xys) instead of separate keys ...
          // ... so that we can use animatedProps.xys.interpolate() to ...
          // ... easily generate the css transform value below.
          xys: [0, 0, 1],
          // Setup physics
          config: { mass: 10, tension: 400, friction: 40, precision: 0.00001 }
        };
      });
    
      return (
        <animated.div
          ref={ref}
          className="gettoknowDiv"
          onMouseEnter={() => setHovered(true)}
          onMouseMove={({ clientX, clientY }) => {
            // Get mouse x position within card
            const x =
              clientX -
              (ref.current.offsetLeft -
                (window.scrollX || window.pageXOffset || document.body.scrollLeft));
    
            // Get mouse y position within card
            const y =
              clientY -
              (ref.current.offsetTop -
                (window.scrollY || window.pageYOffset || document.body.scrollTop));
    
            // Set animated values based on mouse position and card dimensions
            const dampen = 50; // Lower the number the less rotation
            const xys = [
              -(y - ref.current.clientHeight / 2) / dampen, // rotateX
              (x - ref.current.clientWidth / 2) / dampen, // rotateY
              1.07 // Scale
            ];
    
            // Update values to animate to
            setAnimatedProps({ xys: xys });
          }}
          onMouseLeave={() => {
            setHovered(false);
            // Set xys back to original
            setAnimatedProps({ xys: [0, 0, 1] });
          }}
          style={{
            // If hovered we want it to overlap other cards when it scales up
            zIndex: isHovered ? 2 : 1,
            // Interpolate function to handle css changes
            transform: animatedProps.xys.interpolate(
              (x, y, s) =>
                `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
            )
          }}
        >
          {children}
        </animated.div>
      );
    }
}

export default OAR
