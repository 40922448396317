import React from 'react';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingImg from '../../images/author1.png';
import YoutubeIcon from '../../images/youtube.png';
import TwitterIcon from '../../images/twitterIcon.svg';
import sociallink2 from '../../images/socialLink2.png';
import InstaIcon from '../../images/insta.png';
import Raj from '../../images/raj.png';
import LinkedinIcon from '../../images/linkedin.png';
import RightLazyShow from '../rightLazyShow';

class Operator extends React.Component{
  constructor(props){
    super(props);
    this.state={
    }
  }

  render(){
    return(
      <>
        <div className="operatorsMainDiv" id="operator">
          <text className="whyStakeWithmeHeaderText">THE OPERATORS</text>
          <div className="row author1Div">
            <div className="col-lg-5">
              <img src={LandingImg} alt="location" className="author"/>
            </div>
            <div className="col-lg-7 maxMaherCol">
              <text className="landingHeader"><RightLazyShow>Max Maher</RightLazyShow></text>
              <text className="ORABlueHeader blueHeaderDiv"><RightLazyShow>YOUTUBER | ENTREPRENEUR | CRYPTO INVESTOR</RightLazyShow></text>
              <text className="whyMeMainText"><RightLazyShow><p>I have been an entrepreneur for as long as I can remember, starting my first real business at age 13. Over the next years, my businesses had grown substantially to scale across the country. This early success allowed me to follow my passion for investments and finance which naturally led me to stocks and crypto. My first purchase of bitcoin was in 2013 but I didn't start investing heavily until years later.</p> <p>In my crypto journey, Cardano really stood out to me as a contender to be the best. The more I read, the more I believed in the mission. I now spend my time not only running my businesses but also helping to educate investors through grounded, level-headed presentations in the finance and crypto space.</p></RightLazyShow></text>
              <div className="iconsdiv">
                <a href=" https://hi.switchy.io/maxYoutube" target="_blank"><img src={YoutubeIcon} alt="location" className="iconsImg"/></a>
                <a href="https://hi.switchy.io/maxTwitter" target="_blank"><img src={TwitterIcon} alt="location" className="iconsImg"/></a>
                <a href="https://hi.switchy.io/maxInsta" target="_blank"><img src={InstaIcon} alt="location" className="iconsImg"/></a>
                <a href="https://hi.switchy.io/maxPatreon" target="_blank"><img src={sociallink2} alt="location" className="iconsImg"/></a>
              </div>
            </div>
          </div>
          <div className="row author2Div">
            <div className="col-lg-5">
              <img src={Raj} alt="location" className="author"/>
            </div>
            <div className="col-lg-7 maxMaherCol">
              <text className="landingHeader"><RightLazyShow>Rajendra Murakonda</RightLazyShow></text>
              <text className="ORABlueHeader blueHeaderDiv"><RightLazyShow>TECH ENTREPRENEUR | MIT ALUMNI</RightLazyShow></text>
              <text className="whyMeMainText"><RightLazyShow><p>Raj, an alumnus of MIT Sloan, has unique skills in technology, product management, and business strategy. Raj is passionate about blockchain and built identity and KYC solutions on top of Ethereum blockchain. He is an incoming Cardano Plutus Pioneer and has scaled technology and business of many successful startups all over the world.</p> <p>Along with operating the stake pool, he is working on an innovative product in the Cardano eco system. His network and partnerships at MIT and other big firms, and his end-user focused approach makes him invaluable to helping blockchain startups.</p></RightLazyShow></text>
              <div className="iconsdiv">
                <a href="https://hi.switchy.io/rajLinkedin" target="_blank"><img src={LinkedinIcon} alt="location" className="iconsImg"/></a>
                <a href="https://hi.switchy.io/rajTwitter" target="_blank"><img src={TwitterIcon} alt="location" className="iconsImg"/></a>
              </div>
            </div>
          </div>
        </div>
        <div className="separatingLine"></div>
      </>
    )
  }
}

export default Operator