import React, { useState } from "react"
import styled from "styled-components"
import HeaderLinks from "./HeaderLinks"
import Logo from "../Logo/Logo"
import './Header.css'

const NavigationHeader = styled.nav`
  height: 3.2vw;
  width: 93.1vw;
  display: flex;
  background-color: #000000;
  position: relative;
  z-index: 3;
  top: 0;
  font-weight: 600;
  justify-content: space-between;
  margin-left: 3.4vw;
  margin-right: 3.4vw;
  align-self: center;
  border-radius: 1.19vw;

  @media screen and (max-width:767.98px){
    position: relative;
    justify-content:space-between;
    height: 13.8vw;
    width:100%;
    background: ${props => (props.open ? "#000000" : "#000000")};
    left: 0;
    right: 0;
    margin-left:0;
    padding:3%;
    width:100%;
    margin-right:0;
    border-radius:2.5vw;
  }

  @media (min-width: 768px) and (max-width:1024px){
    position: relative;
    justify-content:space-between;
    background-color:#000000;
    left: 0;
    right: 0;
    left: 0;
    padding:1%;
    height: 10.8vw;
    width:100%;
    border-radius:2.5vw;
    margin-left: 0;
    margin-right: 0;
  }
`

const Toggleheader = styled.div`
  cursor: pointer;
  padding: 0;  
  margin-top: 4.5vw;
  display: none;
  width: 7.33vw;
  height: 4.85vw;
  margin-right:2.5vw;

  @media (max-width: 767.78px) {
    display: flex;
  }

  @media screen and (min-width:768px) and (max-width:1024px){
    display: flex;
    height: 80px;
    margin-top: 1.5vw;
    width: 9.33vw;
    height: 6.85vw;
    cursor: pointer;
    padding:0;
    margin-right:1.5vw;
  }
`

const Headerbox = styled.div`
  display: flex;
  font-size:14px;
  margin-right:0%;
  align-items: center;

  @media (max-width: 767.78px) {
    flex-direction: column;
    float:right;
    position: fixed;
    background-color: #000000;
    transition: left 0.2ms ease-out;
    top: ${props => (props.open ? "-110%" : "0vw")};
    width: 68%;
    height: 100%;
    margin-right: 40%;
    padding-top: 12vh;
    margin-left: 29%;
    align-items: flex-start;
  }

  @media (min-width: 768px) and (max-width:1024px){
    flex-direction: column;
    float:right;
    position: fixed;
    background-color: #000000;
    transition: left 0.2ms ease-out;
    top: ${props => (props.open ? "-110%" : "0")};
    width: 56%;
    height: 100%;
    margin-right: 40%;
    padding-top: 18vh;
    margin-left: 45%;
    margin-top: 0%;
    align-items: flex-start;
  }
  
  @media (min-width:1920px){
    margin-right:0%
  }
`

const Hamburgermenu = styled.div`
  background-color: #fff;
  width: 25px;
  height: 3px;
  margin-top:-39px;
  border-radius:50px;
  transition: margin-right .3s ease;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {

    width: 25px;
    height: 3px;
    background-color: #fff;
    border-radius: 50px;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    top: -8px;
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")}; 
    transition: all 0.3s linear;
    top: 8px;
  }
`
const Close = styled.div`
  float:right;
  font-size: 7vw;
  color: white;
  margin-left: 79%;
  margin-top: -29%;
  margin-bottom: 11%;

  @media screen and (min-width:768px) and (max-width:1024px){
    font-size: 6vw;
    color: white;
    margin-left: 80%;
    margin-top: -35%;
    margin-bottom: 2%;
  }
`

const Header = ( props) => {
   const [headerOpen, setHeaderOpen] = useState(false);
   console.log(props)
  return (
    <div className="nav">
      <NavigationHeader path={props.path}>
        <Logo />
        <Toggleheader
          headerOpen={headerOpen}
          onClick={() => setHeaderOpen(!headerOpen)}
        >
          {headerOpen ? <Hamburgermenu open /> : <Hamburgermenu />}
        </Toggleheader>
        {headerOpen ? (
          <Headerbox path={props.path} headerOpen={headerOpen}
          onClick={() => setHeaderOpen(!headerOpen)}>
            <Close onClick={() => setHeaderOpen(!headerOpen)}>&#10005;</Close>
            <HeaderLinks path={props.path}/>
          </Headerbox>
        ) : (
          <Headerbox open>
            <HeaderLinks />
          </Headerbox>
        )}

      </NavigationHeader>
    </div>
  )
}

export default Header

