import React from "react";
import './styles.css';
import RightLazyShow from '../rightLazyShow';

class Security extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }

  render(){
    return(
      <>
      <div className="securityDiv">
        <div className="yourRewardsHeader securityHeading">SECURITY INFO FOR NERDS</div>
        <div className="row">
          <div className="col-lg-6 column1">
            <div className="ORARow OARRow2">
              <div className="numberDiv">
                <text className="whyMeMainText"><RightLazyShow>1</RightLazyShow></text>
              </div>
              <div className="textDiv">
                <text className="whyMeMainText"><RightLazyShow>We have hosted the Cardano node on the cloud for <a className="highlightText">high availability</a>. We have also built a relay server as a backup in case one server is offline. So there’s little chance we might miss minting an assigned block.</RightLazyShow></text>
              </div>
            </div>
            <div className="ORARow OARRow2">
              <div className="numberDiv">
                <text className="whyMeMainText"><RightLazyShow>2</RightLazyShow></text>
              </div>
              <div className="textDiv">
                <text className="whyMeMainText"><RightLazyShow>All certificates and keys were generated on an offline air-gapped machine and stored in a <a className="highlightText">military-grade hardware encrypted device.</a></RightLazyShow></text>
              </div>
            </div>
            <div className="ORARow OARRow2">
              <div className="numberDiv">
                <text className="whyMeMainText"><RightLazyShow>3</RightLazyShow></text>
              </div>
              <div className="textDiv">
                <text className="whyMeMainText"><RightLazyShow>The block server that hosts the Cardano node is <a className="highlightText">extremely secure</a> and is only accessible to relay servers, that too via a single port.</RightLazyShow></text>
              </div>
            </div>
            <div className="ORARow OARRow2">
              <div className="numberDiv">
                <text className="whyMeMainText"><RightLazyShow>4</RightLazyShow></text>
              </div>
              <div className="textDiv">
                <text className="whyMeMainText"><RightLazyShow>Relay servers too can only be <a className="highlightText">accessed by a single verified IP address only.</a></RightLazyShow></text>
              </div>
            </div>
          </div>
          <div className="col-lg-6 column2">
            <div className="ORARow OARRow2">
              <div className="numberDiv">
                <text className="whyMeMainText"><RightLazyShow>5</RightLazyShow></text>
              </div>
              <div className="textDiv">
                <text className="whyMeMainText"><RightLazyShow>All ports on all servers are closed to the outside world.</RightLazyShow></text>
              </div>
            </div>
            <div className="ORARow OARRow2">
              <div className="numberDiv">
                <text className="whyMeMainText"><RightLazyShow>6</RightLazyShow></text>
              </div>
              <div className="textDiv">
                <text className="whyMeMainText"><RightLazyShow>Unauthorized <a className="highlightText">SSH attacks are prevented</a> through the deployment of intrusion detection software (fail2ban).</RightLazyShow></text>
              </div>
            </div>
            <div className="ORARow OARRow2">
              <div className="numberDiv">
                <text className="whyMeMainText"><RightLazyShow>7</RightLazyShow></text>
              </div>
              <div className="textDiv">
                <text className="whyMeMainText"><RightLazyShow>We have implemented <a className="highlightText">protection against DDOS attacks</a> (distributed denial of service attacks).</RightLazyShow></text>
              </div>
            </div>
            <div className="ORARow OARRow2">
              <div className="numberDiv">
                <text className="whyMeMainText"><RightLazyShow>8</RightLazyShow></text>
              </div>
              <div className="textDiv">
                <text className="whyMeMainText"><RightLazyShow> We have disabled all services and features on servers that are not needed to run the pool.</RightLazyShow></text>
              </div>
            </div>
            <div className="ORARow OARRow2">
              <div className="numberDiv">
                <text className="whyMeMainText"><RightLazyShow>9</RightLazyShow></text>
              </div>
              <div className="textDiv">
                <text className="whyMeMainText"><RightLazyShow> The pool is set up as per the specification and <a className="highlightText">guidelines outlined by Charles Hoskinson.</a></RightLazyShow></text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="separatingLine"></div>
      </>
    )
  }
}

export default Security