import React from 'react';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Arrow from '../../images/arrow.svg';
import RightLazyShow from '../rightLazyShow';

class Resources extends React.Component{
  constructor(props){
    super(props);
    this.state={
    }
  }

  render(){
    return(
      <>
      <div className="ResourcesMainDiv" id="resources">
        <text className="whyStakeheading resourcesmainheading">A FEW HANDY CARDANO RESOURCES</text>
        <div className="row">
          <div className="col-lg-4">
            <RightLazyShow>
              <div className="ORABlueSubHeaderDiv resourcehandyRewardsDiv">
                <text className="ORABlueHeader resourcesHandyRewards" onClick={()=>window.open('https://hi.switchy.io/adaradar','_blank')}>CARDANO ECOSYSTEM DAPPS RADAR</text>
                <a href="https://hi.switchy.io/adaradar" className="resourcesArrowImg" target="_blank"><img src={Arrow} alt="location" className="resourcesArrowImg"/></a>
              </div>
            </RightLazyShow>
          </div>
          <div className="col-lg-4">
            <RightLazyShow>
              <div className="ORABlueSubHeaderDiv resourcehandyRewardsDiv">
                <text className="ORABlueHeader resourcesHandyRewards" onClick={()=>window.open('https://hi.switchy.io/adareturnstool','_blank')}>ADA INVESTMENT RETURNS CALCULATOR</text>
                <a href="https://hi.switchy.io/adareturnstool" className="resourcesArrowImg" target="_blank"><img src={Arrow} alt="location" className="resourcesArrowImg"/></a>
              </div>
            </RightLazyShow>
            <RightLazyShow>
              <div className="ORABlueSubHeaderDiv resourcehandyRewardsDiv">
                <text className="ORABlueHeader resourcesHandyRewards" onClick={()=>window.open('https://cardano.org/stake-pool-delegation/','_blank')}>A QUICK GUIDE TO STAKING YOUR ADA</text>
                <a href="https://cardano.org/stake-pool-delegation/" className="resourcesArrowImg" target="_blank"><img src={Arrow} alt="location" className="resourcesArrowImg"/></a>
              </div>
            </RightLazyShow>
          </div>
          <div className="col-lg-4">
            <RightLazyShow>
              <div className="ORABlueSubHeaderDiv resourcehandyRewardsDiv">
                <text className="ORABlueHeader resourcesHandyRewards" onClick={()=>window.open('https://hi.switchy.io/adastakingreturns','_blank')}>ADA STAKING RETURNS CALCULATOR</text>
                <a href="https://hi.switchy.io/adastakingreturns" className="resourcesArrowImg" target="_blank"><img src={Arrow} alt="location" className="resourcesArrowImg"/></a>
              </div>
            </RightLazyShow>
          </div>
        </div>
      </div>
      <div className="separatingLine"></div>
      </>
    )
  }
}

export default Resources