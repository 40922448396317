import * as React from "react"
import '../components/Header/Header.css'
import Layout from '../components/layout/layout'
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingSection from "../components/LandingSection";
import WhyStakeWithMe from '../components/WhyStakeWithMe';
import WhyStakeYourAda from '../components/whyStakeyourada';
import OAR from '../components/OAR';
import GetStarted from '../components/GetStarted';
import YourRewards from "../components/YourRewards";
import Operator from "../components/Operator";
import FollowonYoutube from '../components/FollowonYoutube'
import Resources from "../components/Resources";
import Security from "../components/security";
import SEO from '../components/seo'
import LoaderSection from "../components/LoaderSection";

class Index extends React.Component{
  constructor(props){
    super(props);
    this.state={
      show_loader: true
    }
  }

  handleEvent = (event) => {
    this.setState({ show_loader: false }) 
  }

  render(){
    return(
      <div>
        {this.state.show_loader ? 
        <>
          <LoaderSection  handleEvent={this.handleEvent}/>
        </>
        :
        <Layout path="/">
          <SEO title="Stake With Max" />
          <LandingSection />
          <WhyStakeYourAda />
          <WhyStakeWithMe />
          <Security />
          <OAR />
          <GetStarted />
          <YourRewards />
          <Operator />
          <FollowonYoutube />
          <Resources />
        </Layout>
        }
        </div>
    )
  }
}

export default Index;