import React from 'react';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingImg from '../../images/landingImg.png'
import LandingImgmbl from '../../images/landingImgmbl.png'
import ReactTooltip from "react-tooltip";
import LazyShow from '../lazyShow';
import RightLazyShow from '../rightLazyShow';

class LandingSection extends React.Component{
  constructor(props){
    super(props);
    this.state={
      showTooltip: false
    }
  }

  componentDidMount(){
    this.setState({
      showTooltip: true
    });
  }

  render(){
    return(
      <>
        <div className="row landingMain">
          <div className="col-lg-6 landingText">
            <div className="landingTextSubDiv webLandingDiv">
              <text className="landingHeader"><LazyShow>Come Stake With Me</LazyShow></text>
              <text className="landingSubText"><LazyShow>Grow your ADA without risk and play your part in benefiting charity. Get started with a few clicks.</LazyShow></text>
              <RightLazyShow>
                <div className="buttonsDiv">
                <a href="/#getstarted"><button className="landingButton" >Stake Now</button></a>
                <button className="landingBorderButton" data-tip data-for="registerTip">TICKER: MAX1</button>
                {this.state.showTooltip ? <ReactTooltip id="registerTip" place="bottom" effect="solid">
                  Enter this ticker symbol on your Yoroi or Deadalus wallet to start staking ADA
                </ReactTooltip>: ""}
              </div>
              </RightLazyShow>
            </div>
            <div className="landingTextSubDiv mobileLandingDiv">
              <text className="landingHeader">Come Stake With Me</text>
              <text className="landingSubText">Grow your ADA without risk and play your part in benefiting charity. Get started with a few clicks.</text>
              <div className="buttonsDiv">
                <a href="/#getstarted"><button className="landingButton" >Stake Now</button></a>
                <button className="landingBorderButton" data-tip data-for="registerTip">TICKER: MAX1</button>
                {this.state.showTooltip ? <ReactTooltip id="registerTip" place="bottom" effect="solid">
                  Enter this ticker symbol on your Yoroi or Deadalus wallet to start staking ADA
                </ReactTooltip>: ""}
              </div>
            </div>
          </div>
          <div className="col-lg-6 LandingImgDiv">
            <img src={LandingImg} alt="location" className="LandingImg"/>
            <img src={LandingImgmbl} alt="location" className="LandingImgmbl"/>
          </div>
        </div>
        <div className="separatingLine"></div>
      </>
    )
  }
}

export default LandingSection