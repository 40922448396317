import React from 'react';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactTooltip from "react-tooltip";
import RightLazyShow from '../rightLazyShow';

class WhyStakewithme extends React.Component{
  constructor(props){
    super(props);
    this.state={
      showTooltip: false
    }
  }

  componentDidMount(){
    this.setState({
      showTooltip: true
    });
  }

  render(){
    return(
      <>
        <div className="WhyStakeWithMeMain" id="whystakewithme">
          <text className="whyStakeWithmeHeaderText">WHY STAKE WITH ME?</text>
          <div className="row">
            <div className="col-lg-4 whyMeSubTextDiv">
              <button className="whyMeSubText tickerText" data-tip data-for="registerTip">TICKER: MAX1</button>
                {this.state.showTooltip ? <ReactTooltip id="Tip" place="bottom" effect="solid">
                  Enter this ticker symbol on your Yoroi or Deadalus wallet to start staking ADA
                </ReactTooltip>: ""}
            </div>
            <div className="col-lg-7 landingTextSubDiv">
              <div className="stakeForCharity">
                <text className="whyMeBlueSubHeader"><RightLazyShow>STAKE FOR CHARITY</RightLazyShow></text>
                <text className="whyMeMainText"><RightLazyShow>5% of all REVENUE will go to help children with autism. We Will be contributing regularly to <a href="/#oar">Organization of Autism Research</a>.</RightLazyShow></text>
              </div>
              <text className="whyMeBlueSubHeader"><RightLazyShow>SECURITY</RightLazyShow></text>
              <text className="whyMeMainText"><RightLazyShow>Highly secure stake pool built on AWS with multiple relay servers so that the pool is highly available and fast thus reducing the chance of orphan blocks.</RightLazyShow></text>
            </div>
          </div>
          <div className="section3div2">
            <div className="stakeForCharity">
              <text className="whyMeBlueSubHeader"><RightLazyShow>ACTIVE COMMUNITY</RightLazyShow></text>
              <text className="whyMeMainText"><RightLazyShow>Be a part of an active and thriving Cardano community. <a href='https://hi.switchy.io/maxYoutube' target="_blank" rel="noreferrer">Subscribe to youtube videos</a> for up-to-date information on Cardano. <a href="https://hi.switchy.io/stakewithmaxDiscord" target="_blank" rel="noreferrer">Be a part of discord community for free</a>.</RightLazyShow></text>
            </div>
            <text className="whyMeBlueSubHeader"><RightLazyShow>TECHNICAL EXCELLENCE</RightLazyShow></text>
            <text className="whyMeMainText"><RightLazyShow>The stake pool is run by an <a>alumnus of MIT</a> who has built multiple tech companies with high technical credentials.</RightLazyShow></text>
          </div>
        </div>
        <div className="separatingLine"></div>
      </>
    )
  }
}
export default WhyStakewithme