import React from 'react';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import GetStarted1 from '../../images/getstarted1.png';
import GetStarted2 from '../../images/getStarted2.png';
import GetStartedImg from '../../images/getStartedImg.png';
import ReactTooltip from "react-tooltip";
import RightLazyShow from '../rightLazyShow';


class GetStarted extends React.Component{
  constructor(props){
    super(props);
    this.state={
      showTooltip: false
    }
  }

  componentDidMount(){
    this.setState({
      showTooltip: true
    });
  }

  render(){
    return(
      <>
        <div className="getStartedDiv" id="getstarted">
          <text className="whyStakeWithmeHeaderText">HOW TO GET STARTED</text>
          <div className="row">
            <div className="col-lg-6">
              <iframe src="https://www.youtube.com/embed/IbFijxlb9Io" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="GetStartedImg"></iframe>
              <button className="tickerwhyMeSubText tickerText" data-tip data-for="registerTip">TICKER: MAX1</button>
                {this.state.showTooltip ? <ReactTooltip id="Tip2" place="bottom" effect="solid">
                  Enter this ticker symbol on your Yoroi or Deadalus wallet to start staking ADA
                </ReactTooltip>: ""}
            </div>
            <div className="col-lg-6 col2">
              <div className="ORARow OARRow2">
                <div className="numberDiv">
                  <text className="whyMeMainText"><RightLazyShow>1</RightLazyShow></text>
                </div>
                <div className="textDiv">
                  <text className="whyMeMainText"><RightLazyShow>Download the <a href="https://hi.switchy.io/yoroi" target="_blank">Yoroi</a> or <a href="https://hi.switchy.io/daedalus" target="_blank">Daedalus</a> wallet.</RightLazyShow></text>
                </div>
              </div>
              <div className="ORARow OARRow2">
                <div className="numberDiv">
                  <text className="whyMeMainText"><RightLazyShow>2</RightLazyShow></text>
                </div>
                <div className="textDiv">
                  <text className="whyMeMainText"><RightLazyShow>Go to the Delegation center, search for "MAX1"</RightLazyShow></text>
                </div>
              </div>
              <div className="ORARow OARRow2">
                <div className="numberDiv">
                  <text className="whyMeMainText"><RightLazyShow>3</RightLazyShow></text>
                </div>
                <div className="textDiv">
                  <text className="whyMeMainText"><RightLazyShow>Click on "Delegate". Approve the transaction.</RightLazyShow></text>
                </div>
              </div>
              <div className="ORARow OARRow2">
                <div className="numberDiv">
                  <text className="whyMeMainText"><RightLazyShow>4</RightLazyShow></text>
                </div>
                <div className="textDiv">
                  <text className="whyMeMainText"><RightLazyShow>That's it! You are done delegating and you will start receiving rewards in your wallet after 25 days.</RightLazyShow></text>
                </div>
              </div>
              <div className="ORARow OARRow2">
                <text className="whyMeMainText"><RightLazyShow>Note: If you have your ADA in a centralized exchange such as Coinbase or Kucoin, you need to download Yoroi or Daedalus wallet, create a new wallet, send ADA From your central exchange to the newly created wallet, and then delegate ADA to MAX1 pool.</RightLazyShow></text>
              </div>
              <div className="ORARow OARRow2">
                <text className="whyMeMainText"><RightLazyShow>Stuck? Watch the video to the left for step-by-step instructions.</RightLazyShow></text>
              </div>
              <div className="ORARow OARRow2 getStartedRow">
                <div className="walletrow">
                  <a href="https://hi.switchy.io/yoroi" target="_blank" rel="noreferrer"><img src={GetStarted1} alt="location" className="GetStartedImg1"/></a>
                  <text className="ORABlueHeader GetStartedRow2" onClick={()=> window.open('https://hi.switchy.io/yoroi','_blank')}>GET YOROI WALLET</text>
                </div>
                <div className="walletrow">
                  <a href="https://hi.switchy.io/daedalus" target="_blank" rel="noreferrer"><img src={GetStarted2} alt="location" className="GetStartedImg1"/></a>
                  <text className="ORABlueHeader GetStartedRow2" onClick={()=> window.open('https://hi.switchy.io/daedalus','_blank')}>GET DAEDALUS WALLET</text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="separatingLine"></div>
      </>
    )
  }
}

export default GetStarted;
