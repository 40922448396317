import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import './Header.css'

const HeaderItem = styled(Link)`
text-decoration: none;
color: #FDFEFF;
display: inline-block;
white-space: nowrap;
margin: 1.5vw 0.8vw 0 0.8vw;
font-family: Maax Rounded Regular;
font-size: 1.3vw;
font-weight:500;
line-height: 1.82vw;
cursor:pointer;

&.active{
  position: relative;
}
:hover{
  color: #FDFEFF;
}
@media (max-width: 767.78px) {
  padding: 7vw 0;
  font-size: 15pt;
  z-index: 6;
  font-weight:500;
  color: white; 
  margin-top:-2%;
  margin-left:7%;
  
  &.active{
    background-color: #B8272F;
    width:100%;
    margin-left:-1%;
    padding-left:7%;
  }
}
@media screen and (min-width:768px) and (max-width:1024px){
  padding: 5vw 0;
  font-size: 4.38vw;
  z-index: 6;
  color: white; 
  margin-top:2%;
  padding-left:4%;
  &.active{
    background-color: #B8272F;
    width:100%;
    margin-left:-1%;
    padding-left:7%;
  }
}
`

const HeaderLinks = (props) => {

  return (
    <>
      <HeaderItem to="/">Home</HeaderItem>
      <HeaderItem to='#whystakeyourada'>Why Stake?</HeaderItem>
      <HeaderItem to="#whystakewithme">Why Stake With Me?</HeaderItem>
      <HeaderItem to='#oar' >Charity</HeaderItem>
      <HeaderItem to='#getstarted' >Get Started</HeaderItem>
      <HeaderItem to='#rewards'>Rewards</HeaderItem>
      <HeaderItem to='#operator'>Operators</HeaderItem>
      <HeaderItem to='#resources' >Resources</HeaderItem>
    </>
  )
}

export default HeaderLinks