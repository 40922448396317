import * as React from "react";
import { useState, useEffect } from "react";
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Max_Animate from '../../images/max_animate.gif';
import Logo from '../Logo/Logo'

function LoaderSection({handleEvent}) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if( counter >= 100){
      handleEvent(true);
    }
    else{
      const interval = setInterval(() => {
        setCounter(counter + 5);
      }, 100);

      return () => { 
        clearInterval(interval);
      };
    }
  });

  return(
    <>
      <div className="loaderMain">
        <div className="nav">
          <div className="loaderLogo">
            <Logo />
          </div>
        </div>
        <div className="loaderSubDiv">
          <img className="max_loader_gif" src={Max_Animate} alt="loading..." />
          <text className="loaderText">Busy Generating Passive Income... Please HODL!</text>
        </div>
        <div className="loaderDiv">
          <h1 className="preloader_inner">{counter}%</h1>
        </div>
      </div>
    </>
  )
}

export default LoaderSection