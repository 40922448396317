import React,{ useState, useRef } from 'react';
import './styles.css';
import { useSpring, animated } from 'react-spring';
import LazyShow from '../lazyShow';
import RightLazyShow from '../rightLazyShow'

const WhyStakeyourada=() => {
  
    return(
      <>
        <div className="whystakeDiv" id="whystakeyourada">
          <div className="whyStakeheadingSub">
            <text className="whyStakeheading">WHY STAKE YOUR ADA?</text>
          </div>
          <div className="row">
            
            <div className="col-lg-8 whyStakeCol1">
              <Card>
                <text className="cardHeaderText"><RightLazyShow>RISK FREE</RightLazyShow></text>
                <text className="carddescription"><RightLazyShow>Leaving huge sums of money in a central exchange can be risky. Staking can be done from your personal wallet thus making it RISK FREE.</RightLazyShow></text>
              </Card>
            </div>
            
            <div className="col-lg-4 whyStakeCol2">
              <Card>
                <text className="cardHeaderText"><RightLazyShow>HELP DRIVE ADA PRICE</RightLazyShow></text>
                <text className="carddescription"><RightLazyShow> More than 70% ADA has been staked already and the more you stake the more scarce it becomes thus driving the price of your investment further up.</RightLazyShow></text>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 whyStakeCol3">
              <Card>
                <text className="cardHeaderText"><RightLazyShow>STEADY RETURNS</RightLazyShow></text>
                <text className="carddescription"><RightLazyShow>Staking will give you ~5% return on ADA per year.</RightLazyShow></text>
              </Card>
            </div>
            <div className="col-lg-3 whyStakeCol4">
              <Card>
                <text className="cardHeaderText"><RightLazyShow>LIQUIDITY</RightLazyShow></text>
                <text className="carddescription"><RightLazyShow> You always have your ADA with you while staking. Any money you add or remove from your wallet will automatically adjust your staking amount.</RightLazyShow></text>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 whyStakeCol5">
              <Card>
                <text className="cardHeaderText"><RightLazyShow>CONTRIBUTE TO THE ECOSYSTEM</RightLazyShow></text>
                <text className="carddescription"><RightLazyShow>Staking helps you contribute to the Cardano community by providing consensus.</RightLazyShow></text>
              </Card>
            </div>
            <div className="col-lg-6 whyStakeCol6">
              <Card>
                <text className="cardHeaderText"><RightLazyShow>CONTRIBUTE TO TRUE DECENTRALIZATION</RightLazyShow></text>
                <text className="carddescription"><RightLazyShow>Staking helps you contribute to the Cardano community by providing consensus.</RightLazyShow></text>
              </Card>
            </div>
          </div>
        </div>
        <div className="separatingLine"></div>
      </>
    )

    function Card({ children }) {
      // We add this ref to card element and use in onMouseMove event ...
      // ... to get element's offset and dimensions.
      const ref = useRef();
    
      // Keep track of whether card is hovered so we can increment ...
      // ... zIndex to ensure it shows up above other cards when animation causes overlap.
      const [isHovered, setHovered] = useState(false);
    
      const [animatedProps, setAnimatedProps] = useSpring(() => {
        return {
          // Array containing [rotateX, rotateY, and scale] values.
          // We store under a single key (xys) instead of separate keys ...
          // ... so that we can use animatedProps.xys.interpolate() to ...
          // ... easily generate the css transform value below.
          xys: [0, 0, 1],
          // Setup physics
          config: { mass: 10, tension: 400, friction: 40, precision: 0.00001 }
        };
      });
    
      return (
        <animated.div
          ref={ref}
          className="stakecardbg"
          onMouseEnter={() => setHovered(true)}
          onMouseMove={({ clientX, clientY }) => {
            // Get mouse x position within card
            const x =
              clientX -
              (ref.current.offsetLeft -
                (window.scrollX || window.pageXOffset || document.body.scrollLeft));
    
            // Get mouse y position within card
            const y =
              clientY -
              (ref.current.offsetTop -
                (window.scrollY || window.pageYOffset || document.body.scrollTop));
    
            // Set animated values based on mouse position and card dimensions
            const dampen = 50; // Lower the number the less rotation
            const xys = [
              -(y - ref.current.clientHeight / 2) / dampen, // rotateX
              (x - ref.current.clientWidth / 2) / dampen, // rotateY
              1.07 // Scale
            ];
    
            // Update values to animate to
            setAnimatedProps({ xys: xys });
          }}
          onMouseLeave={() => {
            setHovered(false);
            // Set xys back to original
            setAnimatedProps({ xys: [0, 0, 1] });
          }}
          style={{
            // If hovered we want it to overlap other cards when it scales up
            zIndex: isHovered ? 2 : 1,
            // Interpolate function to handle css changes
            transform: animatedProps.xys.interpolate(
              (x, y, s) =>
                `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
            )
          }}
        >
          {children}
        </animated.div>
      );
    }
}

export default WhyStakeyourada;