import React, { useState} from 'react';
import './styles.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css";
import RightArrow from '../../images/rightArrow.png';
import LeftArrow from '../../images/leftArrow.png';
import RightLazyShow from '../rightLazyShow';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img src={RightArrow} className="arrowSize" onClick={onClick}/>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img src={LeftArrow} className="arrowSize" onClick={onClick}/>
  );
}


const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  swipeToSlide: true,
  nextArrow: <SampleNextArrow/>,
  prevArrow: <SamplePrevArrow/>,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const FollowonYoutube=()=>{
  
    return(
      <>
      <div className="followDiv" >
        <div className="folowHeading">
          <text className="whyStakeheading">FOLLOW ME ON YOUTUBE</text>
        </div>
        <RightLazyShow><p className="followdescription">I share highly researched Cardano and Crypto updates every day... You don't want to miss this!</p></RightLazyShow>
         <Slider {...settings} >
          <div>
          <iframe src="https://www.youtube.com/embed/Tw9QZv59P0I" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
          <iframe src="https://www.youtube.com/embed/tpGkr71J-0c" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
          <iframe src="https://www.youtube.com/embed/f_huooxi6SM" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
            <iframe src="https://www.youtube.com/embed/o7fyfKti0m4" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
            <iframe src="https://www.youtube.com/embed/zyREpnx6zn0" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
            <iframe src="https://www.youtube.com/embed/eXYOF3RwAQg" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
            <iframe src="https://www.youtube.com/embed/KMaZ0oG4bqE" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
            <iframe src="https://www.youtube.com/embed/dv_2LNQI0XA" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
            <iframe src="https://www.youtube.com/embed/FAvGtsYoqes" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
            <iframe src="https://www.youtube.com/embed/4Pkrk28cq9Y" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
            <iframe src="https://www.youtube.com/embed/BNpHQWlZZS4" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
            <iframe src="https://www.youtube.com/embed/m7z-Jlurfh0" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
            <iframe src="https://www.youtube.com/embed/2Kd5qDX8yI8" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
            <iframe src="https://www.youtube.com/embed/frCRCAUf7Ok" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
          <div>
            <iframe src="https://www.youtube.com/embed/J9lduaNiyC4" loading="lazy" frameBorder="0" allowFullScreen="" id="1977951806" className="youtubeimg"></iframe>
          </div>
        </Slider>

      </div>
      <div className="separatingLine"></div>
      </>
    )
  
}

export default FollowonYoutube