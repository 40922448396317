import React from 'react';
import './Footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterLogo from '../../images/max_logo.png';
import twitterIcon from '../../images/twitterIcon.svg';
import discordIcon from '../../images/discord.png';
import MMILogo from '../../images/mmiLogo.png'


class Footer extends React.Component{
  constructor(props){
    super(props);
    this.state={
      name: '',
      email:'',
      feedbackMsg:''
    }
  }

  scrollSmoothTo(elementId) {
    var element = document.getElementById(elementId);
    element.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }

  render(){
    return(
      <>
        <div className="footerContainer">
          <div className="row">
            <div className="mobileIcon">
              <img src={FooterLogo} alt="location" className="footerlogo"/>
            </div>
            <div className="col-lg-4 footerdiv1">
              <text className="siteMapHeader">SITE MAP</text>
              <div className="sitemapSubContent">
                <text className="sitemapSubContentText" onClick={()=>window.scrollTo(0,0)}>Home</text>
                <text className="sitemapSubContentText" onClick={()=> this.scrollSmoothTo('whystakeyourada')}>Why Stake Ada?</text>
                <text className="sitemapSubContentText" onClick={()=> this.scrollSmoothTo('whystakewithme')}>Why Stake With Me?</text>
                <text className="sitemapSubContentText" onClick={()=> this.scrollSmoothTo('oar')}>Supporting OAR</text>
                <text className="sitemapSubContentText" onClick={()=> this.scrollSmoothTo('getstarted')}>How To Get Started</text>
                <text className="sitemapSubContentText" onClick={()=> this.scrollSmoothTo('rewards')}>Your Rewards</text>
                <text className="sitemapSubContentText" onClick={()=> this.scrollSmoothTo('operator')}>The Operators</text>
                <text className="sitemapSubContentText" onClick={()=> this.scrollSmoothTo('resources')}>Resources</text>
              </div>
            </div>
            <div className="col-lg-4 footerdiv2">
              <img src={FooterLogo} alt="location" className="footerlogo"/>
            </div>
            <div className="col-lg-4 footerdiv3">
              <div>
                <text className="siteMapHeader">JOIN US ON <br/>TELEGRAM AND<br/> TWITTER FOR STAKE<br/> POOL UPDATES</text>
                <div className="socialLinksDiv">
                  {/* <img src={telegramIcon} alt="location" className="socialLinksImg"/> */}
                  <a href="https://hi.switchy.io/stakewithmaxDiscord" target="_blank" rel="noreferrer"><img src={discordIcon} alt="location" className="socialLinksImg"/></a>
                  <a href="https://twitter.com/maxwell_maher?lang=en" target="_blank" className="socialLinksImg1"><img src={twitterIcon} alt="location" className="socialLinksImg"/></a>
                </div>
                <div className="mmiDiv">
                <a href="https://hi.switchy.io/mmi" target="_blank"><img src={MMILogo} alt="location" className="mmiImg"/></a>
                  <text className="sitemapSubContentText">Built By<br/> MakeMyIdea</text>
                </div>
              </div>
            </div>
          </div>
          <div className="subFooter">
            <ul className="unorderedList">
              <text className="sitemapSubContentText copyright">© Copyright 2021</text>
              <li className="sitemapSubContentText copyright">All Rights Reserved</li>
              {/* <li className="sitemapSubContentText underline" onClick={() => window.open('https://www.termsandconditionsgenerator.com/live.php?token=heqaWNe4ZOW1A41ARhKX8ygKwUllRdp4','_target')}>Terms of Service</li>
              <li className="sitemapSubContentText underline" onClick={() => window.open('https://www.termsfeed.com/live/12f01196-28e5-49a6-a18f-ba964a4628e8','_target')}>Privacy Policy</li> */}
            </ul>
          </div>
        </div>
      </>
    )
  }
}

export default Footer